import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AesCrypto from './service/AesCrypto'
import UuidSeq from 'uuid-sequential'

export default class AppLib {
  static pad (n, width) {
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
  }

  static validateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  static nowTimestamp () {
    return (new Date().getTime())
  }

  static getTimestampFromformat (year, month, day) {
    const stamp = new Date(year, month - 1, day)
    return stamp.getTime()
  }

  static getTimestampFromString (dateTime) { // yyyy-MM-dd HH:mm:ss
    const reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
    const dateArray = reggie.exec(dateTime)
    const dateObject = new Date(
      (+dateArray[1]),
      (+dateArray[2]) - 1, // Careful, month starts at 0!
      (+dateArray[3]),
      (+dateArray[4]),
      (+dateArray[5]),
      (+dateArray[6])
    )
    return dateObject.getTime()
  }

  static getTimestampFromStringNoHyphen (dateTime) { // yyyyMMddHH:mm
    const reggie = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/
    const dateArray = reggie.exec(dateTime)
    const dateObject = new Date(
      (+dateArray[1]),
      (+dateArray[2]) - 1, // Careful, month starts at 0!
      (+dateArray[3]),
      (+dateArray[4]),
      (+dateArray[5])
    )
    return dateObject.getTime()
  }

  static getWeekFromMonth (year, month) {
    const lastDay = this.getMonthLastDay(year, month)
    const result = []
    const stDay = new Date(year, month - 1, 1).getDay()
    result.push(stDay)
    for (let i = 1; i < lastDay; i++) {
      result.push((stDay + i) % 7)
    }
    return result
  }

  static getWeekFromDateFormat (formatDate) { // yyyy-MM-dd
    const y = parseInt(formatDate.substring(0, 4), 10)
    const m = parseInt(formatDate.substring(5, 7), 10)
    const d = parseInt(formatDate.substring(8, 10), 10)

    return new Date(y, m - 1, d).getDay()
  }

  static getWeekFromTimestamp (tiemstamp) { // yyyy-MM-dd
    return new Date(tiemstamp).getDay()
  }

  static getWeekNameFromTimestamp (tiemstamp) { // yyyy-MM-dd
    const week = new Date(tiemstamp).getDay()
    let date = ''
    switch (week) {
      case 0:
        date = '일'
        break
      case 1:
        date = '월'
        break
      case 2:
        date = '화'
        break
      case 3:
        date = '수'
        break
      case 4:
        date = '목'
        break
      case 5:
        date = '금'
        break
      case 6:
        date = '토'
        break
    }
    return date
  }

  static getWeekNameFromWeekDay (week) {
    let date = ''
    switch (week) {
      case 0:
        date = '일'
        break
      case 1:
        date = '월'
        break
      case 2:
        date = '화'
        break
      case 3:
        date = '수'
        break
      case 4:
        date = '목'
        break
      case 5:
        date = '금'
        break
      case 6:
        date = '토'
        break
    }
    return date
  }

  static getDateWeekFromTimeStamp (timeStamp) { // retrun yyyy-MM-dd(목)
    let date = this.formatYMD(timeStamp)
    const week = new Date(timeStamp).getDay()
    switch (week) {
      case 0:
        date = `${date}(일)`
        break
      case 1:
        date = `${date}(월)`
        break
      case 2:
        date = `${date}(화)`
        break
      case 3:
        date = `${date}(수)`
        break
      case 4:
        date = `${date}(목)`
        break
      case 5:
        date = `${date}(금)`
        break
      case 6:
        date = `${date}(토)`
        break
    }
    return date
  }

  static getThisYear () {
    const dat1 = new Date()
    const thisYear = dat1.getFullYear()
    return parseInt(thisYear)
  }

  static getNextYear () {
    const dat1 = new Date()
    const thisYear = dat1.getFullYear() + 1
    return parseInt(thisYear)
  }

  static getThisMonth () {
    const dat1 = new Date()
    let month = '' + (dat1.getMonth() + 1)
    if (month.length < 2) month = '0' + month
    return month
  }

  static getNextMonth () {
    const dat1 = new Date()
    let month = `${dat1.getMonth() + 2}`
    if (month === '13') {
      month = '01'
    }
    if (month.length < 2) month = '0' + month
    return month
  }

  static quarterYear (month) {
    return Math.ceil(month / 3)
  }

  static getLastMonth () {
    const dat1 = new Date()
    let month = (dat1.getMonth())
    if (month === 0) {
      return '12'
    }
    month = '' + month
    if (month.length < 2) month = '0' + month
    return month
  }

  static getMonthLastDay (year, month) {
    return (new Date(parseInt(year), parseInt(month), 0)).getDate()
  }

  static getMonthLastDayFromTimestamp (timeStamp) {
    const d = new Date(timeStamp)
    const month = '' + (d.getMonth() + 1)
    const year = '' + d.getFullYear()
    return this.getMonthLastDay(year, month)
  }

  static formatDate (timeStamp) {
    if (timeStamp === undefined) { return null }
    const d = new Date(timeStamp)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()
    let time = '' + d.getHours()
    let min = '' + d.getMinutes()
    let sec = '' + d.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (time.length < 2) time = '0' + time
    if (min.length < 2) min = '0' + min
    if (sec.length < 2) sec = '0' + sec

    const dayTemp = [year, month, day].join('-')
    const timeTemp = [time, min, sec].join(':')

    return dayTemp + ' ' + timeTemp
  }

  static formatYMD (timeStamp) {
    if (timeStamp === undefined) { return null }
    const d = new Date(timeStamp)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    const dayTemp = [year, month, day].join('-')
    return dayTemp
  }

  static getMonthFromTimeStamp (timeStamp) {
    if (timeStamp === undefined) { return null }
    const dateTimeType = new Date(timeStamp)
    const month = '' + (dateTimeType.getMonth() + 1)
    return month
  }

  static getDateFromTimeStamp (timeStamp) {
    if (timeStamp === undefined) { return null }
    const dateTimeType = new Date(timeStamp)
    const date = '' + dateTimeType.getDate()
    return date
  }

  static getTimeFromTimeStamp (timeStamp) {
    if (timeStamp === undefined) { return null }
    const dateTimeType = new Date(timeStamp)
    let time = '' + dateTimeType.getHours()
    if (time.length < 2) time = '0' + time
    return time
  }

  static formatDateTimeFromTimeStamp (timeStamp) {
    if (timeStamp === undefined) { return null }
    const dateTimeType = new Date(timeStamp)
    let month = '' + (dateTimeType.getMonth() + 1)
    let day = '' + dateTimeType.getDate()
    const year = '' + dateTimeType.getFullYear()
    let time = '' + dateTimeType.getHours()
    let min = '' + dateTimeType.getMinutes()
    let sec = '' + dateTimeType.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (time.length < 2) time = '0' + time
    if (min.length < 2) min = '0' + min
    if (sec.length < 2) sec = '0' + sec
    const dayTemp = [year, month, day].join('-')
    const timeTemp = [time, min, sec].join(':')
    return dayTemp + ' ' + timeTemp
  }

  static formatDateFromTimeStamp (timeStamp) {
    if (timeStamp === undefined || timeStamp === null) { return null }
    const dateTimeType = new Date(timeStamp)
    let month = '' + (dateTimeType.getMonth() + 1)
    let day = '' + dateTimeType.getDate()
    const year = '' + dateTimeType.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
  }

  static formatDateTime (dateTimeType) {
    if (dateTimeType === undefined || dateTimeType === null) { return null }
    let month = '' + (dateTimeType.getMonth() + 1)
    let day = '' + dateTimeType.getDate()
    const year = '' + dateTimeType.getFullYear()
    let time = '' + dateTimeType.getHours()
    let min = '' + dateTimeType.getMinutes()
    let sec = '' + dateTimeType.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (time.length < 2) time = '0' + time
    if (min.length < 2) min = '0' + min
    if (sec.length < 2) sec = '0' + sec

    const dayTemp = [year, month, day].join('-')
    const timeTemp = [time, min, sec].join(':')
    return dayTemp + ' ' + timeTemp
  }

  static getTodayToMonth () {
    const d = new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month].join('-')
  }

  static getToday () {
    const d = new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  static getNow () {
    const dateTimeType = new Date()
    let month = '' + (dateTimeType.getMonth() + 1)
    let day = '' + dateTimeType.getDate()
    const year = '' + dateTimeType.getFullYear()
    let time = '' + dateTimeType.getHours()
    let min = '' + dateTimeType.getMinutes()
    let sec = '' + dateTimeType.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (time.length < 2) time = '0' + time
    if (min.length < 2) min = '0' + min
    if (sec.length < 2) sec = '0' + sec
    const dayTemp = [year, month, day].join('-')
    const timeTemp = [time, min, sec].join(':')
    return dayTemp + ' ' + timeTemp
  }

  static lastDayThisMonth () {
    const d = new Date()
    let month = '' + (d.getMonth() + 1)
    const year = '' + d.getFullYear()

    let lastDay = (new Date(d.getFullYear(), (d.getMonth() + 1), 0)).getDate()
    if (month.length < 2) month = '0' + month
    if (lastDay.length < 2) lastDay = '0' + lastDay
    return [year, month, lastDay].join('-')
  }

  static calcDateFromToday (value) {
    const today = new Date()
    today.setDate(today.getDate() + value)

    let month = '' + (today.getMonth() + 1)
    let day = '' + today.getDate()
    const year = '' + today.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  static calcMonthFromToday (value) {
    const today = new Date()
    today.setMonth(today.getMonth() + value)

    let month = '' + (today.getMonth() + 1)
    let day = '' + today.getDate()
    const year = '' + today.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  static calcYearFromToday (value) {
    const today = new Date()
    today.setFullYear(today.getFullYear() + value)

    let month = '' + (today.getMonth() + 1)
    let day = '' + today.getDate()
    const year = '' + today.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  static calcDateFromClassDate (date, add) {
    date.setDate(date.getDate() + add)

    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = '' + date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  static calcDateFromFormat (formatDate, addDate) { // yyyy-MM-dd
    let y = parseInt(formatDate.substring(0, 4), 10)
    let m = parseInt(formatDate.substring(5, 7), 10)
    let d = parseInt(formatDate.substring(8, 10), 10)
    const date = new Date(y, m - 1, d)

    date.setDate(date.getDate() + addDate)

    m = '' + (date.getMonth() + 1)
    d = '' + date.getDate()
    y = '' + date.getFullYear()

    if (m.length < 2) m = '0' + m
    if (d.length < 2) d = '0' + d

    return [y, m, d].join('-')
  }

  static calcDaysFromToday (value) {
    const today = new Date()
    today.setDate(today.getDate() + value)

    return today.getDate()
  }

  static isThisMonth (timeStamp) {
    const dateTimeType = new Date(timeStamp)
    const month = (dateTimeType.getMonth() + 1)
    const year = dateTimeType.getFullYear()

    const today = new Date()
    const thisMonth = (today.getMonth() + 1)
    const thisYear = today.getFullYear()

    return (month === thisMonth && year === thisYear)
  }

  static replaceAll (str, searchStr, replaceStr) {
    if (str === undefined || str === null) {
      return null
    }
    return str.split(searchStr).join(replaceStr)
  }

  static getMonthEntoKo (enMonth) {
    let result = ''
    switch (enMonth.toLowerCase()) {
      case 'december':
        result = '12월'
        break
      case 'november':
        result = '11월'
        break
      case 'october':
        result = '10월'
        break
      case 'september':
        result = '9월'
        break
      case 'august':
        result = '8월'
        break
      case 'july':
        result = '7월'
        break
      case 'june':
        result = '6월'
        break
      case 'may':
        result = '5월'
        break
      case 'april':
        result = '4월'
        break
      case 'march':
        result = '3월'
        break
      case 'february':
        result = '2월'
        break
      case 'january':
        result = '1월'
        break
      default:
        result = enMonth
        break
    }
    return result
  }

  static changeDateEnToKo (date) {
    const split = date.split(' ')
    let result = ''
    switch (split[0].toLowerCase()) {
      case 'december':
        result = split[1] + ' 12월'
        break
      case 'november':
        result = split[1] + ' 11월'
        break
      case 'october':
        result = split[1] + ' 10월'
        break
      case 'september':
        result = split[1] + ' 9월'
        break
      case 'august':
        result = split[1] + ' 8월'
        break
      case 'july':
        result = split[1] + ' 7월'
        break
      case 'june':
        result = split[1] + ' 6월'
        break
      case 'may':
        result = split[1] + ' 5월'
        break
      case 'april':
        result = split[1] + ' 4월'
        break
      case 'march':
        result = split[1] + ' 3월'
        break
      case 'february':
        result = split[1] + ' 2월'
        break
      case 'january':
        result = split[1] + ' 1월'
        break
      default:
        result = ''
        break
    }
    return result
  }

  static comma (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  static uncomma (str) {
    str = String(str)
    var minus = str.substring(0, 1)

    str = str.replace(/[^\d]+/g, '')

    if (minus === '-') str = '-' + str
    return str
  }

  static getChangedContentFromRow (underscore, row) {
    let content = ''
    let column = null
    for (var key in row.newData) {
      let newValue = row.newData[key]
      let oldValue = row.oldData[key]
      for (let j = 0; j < row.component._options._optionManager._options.columns.length; j++) {
        if (row.component._options._optionManager._options.columns[j].caption === undefined ||
          row.component._options._optionManager._options.columns[j].caption === '고객명' || row.component._options._optionManager._options.columns[j].caption === '법인명') {
          continue
        }
        column = underscore.findWhere(row.component._options._optionManager._options.columns[j].columns, { dataField: key })
        // console.log(column)
        if (column === undefined || column === null) {
          continue
        }
        if (column.lookup !== undefined) {
          let baseData = underscore.where(column.lookup.dataSource, { numValue: newValue })
          if (baseData === null || baseData === undefined) {
            baseData = underscore.where(column.lookup.dataSource, { textValue: newValue })
          }
          console.log(baseData)
          newValue = baseData[0].dataName

          baseData = underscore.where(column.lookup.dataSource, { numValue: oldValue })
          if (baseData === null || baseData === undefined) {
            baseData = underscore.where(column.lookup.dataSource, { textValue: oldValue })
          }
          // console.log(baseData)
          oldValue = baseData[0].dataName
          break
        }
      }
      // console.log(newValue)
      // console.log(oldValue)
      // console.log(column.caption)
      content += '[ ' + column.caption + ' : ' + oldValue + ' => ' + newValue + ' ] '
    }
    console.log(content)
    return content
  }

  static isNumeric (value) {
    return !isNaN(value)
  }

  static hyphenTelNo (value) {
    if (value === null) {
      return
    }
    if (value.substring(0, 2) === '02') {
      if (value.length === 9) {
        return `${value.substring(0, 2)}-${value.substring(2, 5)}-${value.substring(5, 9)}`
      } else if (value.length > 9) {
        return `${value.substring(0, 2)}-${value.substring(2, 6)}-${value.substring(6, value.length)}`
      } else {
        return value
      }
    } else if (value.substring(0, 1) === '1' || value.substring(0, 1) === '2') {
      return `${value.substring(0, 4)}-${value.substring(4, value.length)}`
    } else {
      if (value.length === 10) {
        return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6, 10)}`
      } else if (value.length > 10) {
        return `${value.substring(0, 3)}-${value.substring(3, 7)}-${value.substring(7, value.length)}`
      } else {
        return value
      }
    }
  }

  static hyphenBusinessNo (value) {
    if (value === null) {
      return
    }
    return `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5, value.length)}`
  }

  static parsingQueryString (url, key) {
    let params = url.substr(url.indexOf('?') + 1)
    let sval = ''
    params = params.split('&')
    for (var i = 0; i < params.length; i++) {
      const temp = params[i].split('=')
      if (temp[0] === key) {
        sval = temp[1]
        break
      }
    }
    return sval
  }

  static uniqueId () {
    return Math.floor(Math.random() * Date.now())
  }

  static getTextLengthAscii (str) {
    let len = 0
    for (let i = 0; i < str.length; i++) {
      if (escape(str.charAt(i)).length === 6) {
        len++
      }
      len++
    }
    return len
  }

  static deleteUnderlineKey (json) {
    const keys = Object.keys(json)
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].indexOf('__') === 0) {
        delete json[keys[i] + '']
        break
      }
    }
    console.log(json)
    return json
  }

  static getBase64SameLength (code) {
    // const addString = 'HONGIKIT01234678900123465789012345678901234567989'
    // let len = 0
    let base64 = null
    if (ConstDef.ENCODE_BASE64) {
      base64 = AesCrypto.base64Encode(code)
      // len = 50 - base64.length
      // base64 += addString.substring(0, len)
    } else {
      base64 = code
      // len = 20 - base64.length
      // base64 += addString.substring(0, len)
    }
    return base64
  }

  static getCellFromExcel (row, index) {
    let result = row.getCell(index).value
    if (result !== null) {
      result = `${result}`.trim()
      if (result.length === 0) {
        result = null
      }
    }
    return result
  }

  static uuidSequential () {
    return UuidSeq()
  }

  static getGroupByList (underscore, keyList, key) {
    const gBykey = underscore.groupBy(keyList, function (list) { return list[key] })
    const array = Object.keys(gBykey)
    const gbyList = []
    for (var i in array) {
      if (array[i] !== 'null') {
        gbyList.push({ [key]: array[i] })
      }
    }
    return underscore.sortBy(gbyList, key)
  }
}
